import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage, Input } from '@tomorrow/ui';
import { PDFViewer } from '../components/PDFViewer';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import testPdf from '../assets/test.pdf';

export function Home() {
  const { data: user } = useCurrentUser();
  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-lg">Welcome home, {user?.firstName}</h1>

      <BasicForm />

      <Divider />

      <div className="flex max-h-[350px]">
        <PDFViewer file={testPdf} fileName="test.pdf" initialScale={1.2} />
      </div>
    </div>
  );
}

type BasicFormValues = {
  username: string;
};

const BasicForm = () => {
  const form = useForm<BasicFormValues>({
    defaultValues: {
      username: '',
    },
  });

  const [submittedValues, setSubmittedValues] = useState<BasicFormValues>();

  function onSubmit(data: BasicFormValues) {
    setSubmittedValues(data);
  }

  return (
    <div>
      <Form {...form}>
        <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Username" size="small" {...field} />
                </FormControl>
                <FormDescription>This is your public display name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
            rules={{ required: 'Username is required.' }}
          />
          <Button type="submit">Submit</Button>
        </form>
      </Form>

      {form.formState.isSubmitSuccessful && (
        <div>
          <p className="text-md">You submitted the following values:</p>
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">{JSON.stringify(submittedValues, null, 2)}</code>
          </pre>
        </div>
      )}
    </div>
  );
};
