import { RiExternalLinkLine } from '@remixicon/react';
import Link from '@mui/material/Link';

export function OmsLink({ id }: { id: string }) {
  const href = `https://retool.tomorrowhealth.com/apps/OMS/Order%20-%20View%20%7C%20Update#orderFriendlyId=${id}`;
  return (
    <Link href={href} px={0.5} rel="noopener noreferrer" sx={{ cursor: 'pointer' }} target="_blank">
      <span>OMS</span>
      <RiExternalLinkLine size={12} />
    </Link>
  );
}
