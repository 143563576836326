import { ColorsV2 } from '@tomorrow/ui/theme';
import { displayDate } from '@tomorrow/ui/utils';
import { OmsLink } from './OmsLink';
import { OrderForSplitType } from '../../../../hooks/useGetOrderForSplit';
import { parseISO } from 'date-fns';
import { RiCalendarLine, RiFileList2Line, RiTruckLine, RiUserLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function OrderHeader({ order }: { order: NonNullable<OrderForSplitType> }) {
  return (
    <Box maxWidth="985px" px={4} sx={{ margin: '0 auto' }}>
      <Box>
        <Box display="flex" gap={0.5}>
          <Typography color="textSecondary" fontWeight="600">
            Original Order
          </Typography>
          <Box display="inline-flex">
            <OmsLink id={order.friendlyId!} />
          </Box>
        </Box>
        <Typography color="textPrimary" fontWeight="600" variant="h5">
          {order.friendlyId}
        </Typography>
      </Box>

      <Box py={1}>
        <OrderHeaderItem icon="calendar" title="Created date" value={displayDate(parseISO(order.createdAt))} />
        <OrderHeaderItem icon="truck" title="Service" value={order.urgent ? 'Urgent' : 'Standard'} />
        <OrderHeaderItem icon="user" title="Patient" value={`${order.patient.firstName} ${order.patient.lastName}`} />
        {order.referrer && order.referringOrg && (
          <OrderHeaderItem
            icon="list"
            title="Referred by"
            value={`${order.referrer.firstName} ${order.referrer.lastName} from ${order.referringOrg.name}`}
          />
        )}
      </Box>
    </Box>
  );
}

type IconName = 'calendar' | 'truck' | 'user' | 'list';

function getIcon(name: IconName, props = { color: ColorsV2.gray_medium, size: '16px' }) {
  switch (name) {
    case 'calendar':
      return <RiCalendarLine {...props} />;
    case 'truck':
      return <RiTruckLine {...props} />;
    case 'user':
      return <RiUserLine {...props} />;
    case 'list':
      return <RiFileList2Line {...props} />;
    default:
      return null;
  }
}

function OrderHeaderItem({ title, value, icon }: { title: string; value: string; icon: IconName }) {
  const iconComponent = getIcon(icon);
  return (
    <Box alignItems="center" display="flex" gap={0.5}>
      {iconComponent}
      <Box>
        <span className="text-muted-foreground">{title}: </span>
        <span>{value}</span>
      </Box>
    </Box>
  );
}
