import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    emailAddress: string;
  } | null;
};

export const GetUserDocument = `
    query getUser {
  user {
    id
    firstName
    lastName
    emailAddress
  }
}
    `;

export const useGetUserQuery = <TData = GetUserQuery, TError = unknown>(
  variables?: GetUserQueryVariables,
  options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetUserQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetUserQuery, TError, TData>({
    queryKey: variables === undefined ? ['getUser'] : ['getUser', variables],
    queryFn: fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
    ...options,
  });
};

useGetUserQuery.getKey = (variables?: GetUserQueryVariables) =>
  variables === undefined ? ['getUser'] : ['getUser', variables];

export const useInfiniteGetUserQuery = <TData = InfiniteData<GetUserQuery>, TError = unknown>(
  variables: GetUserQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetUserQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetUserQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetUserQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: (optionsQueryKey ?? variables === undefined) ? ['getUser.infinite'] : ['getUser.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetUserQuery.getKey = (variables?: GetUserQueryVariables) =>
  variables === undefined ? ['getUser.infinite'] : ['getUser.infinite', variables];

useGetUserQuery.fetcher = (variables?: GetUserQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { user }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetUserQuery = (
  resolver: GraphQLResponseResolver<GetUserQuery, GetUserQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetUserQuery, GetUserQueryVariables>('getUser', resolver, options);
