import { googleLogout } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Cookie from 'js-cookie';

export class GoogleClient {
  private token: string | undefined;

  private token_expiry: number | undefined;

  isAuthenticated(): boolean {
    return !!Cookie.get('authenticated');
  }

  isTokenCloseToExpiry(): boolean {
    if (!this.token_expiry) {
      return true;
    }

    return this.token_expiry - Date.now() / 1000 < 60;
  }

  logout({ logoutParams }: { logoutParams: { returnTo: string } }) {
    fetch('.netlify/functions/logout', {
      method: 'POST',
      body: JSON.stringify({ returnTo: logoutParams.returnTo }),
    }).then(() => {
      googleLogout();
      window.location.href = logoutParams.returnTo;
    });
  }

  async getTokenSilently() {
    if (!this.token) {
      const response = await fetch('.netlify/functions/token').then((res) => res.json());

      if (!response?.idToken) {
        throw new Error('No id token returned from token endpoint');
      }

      const decodedToken = jwtDecode(response.idToken);
      this.token_expiry = decodedToken.exp;
      this.token = response.idToken;
    } else if (this.isTokenCloseToExpiry()) {
      const response = await fetch('.netlify/functions/refresh-token').then((res) => res.json());

      if (!response?.idToken) {
        throw new Error('No id token returned from refresh token endpoint');
      }

      const decodedToken = jwtDecode(response.idToken);
      this.token_expiry = decodedToken.exp;
      this.token = response.idToken;
    }

    return this.token;
  }
}
