import { GetUserQuery, useGetUserQuery } from '../api-clients/falcon-api/graphql/queries/getUser.generated';
import { useAuth } from '../contexts/AuthContext/AuthContext';

export type User = NonNullable<ReturnType<typeof transformGetUserQuery>>;

export function useCurrentUser() {
  const auth = useAuth();

  return useGetUserQuery(
    {},
    {
      enabled: auth.status === 'authenticated',
      staleTime: Infinity,
      meta: {
        errorMessage: 'Something went wrong getting your user information.  Please try again later.',
      },
      select: transformGetUserQuery,
      retry: 3,
    },
  );
}

function transformGetUserQuery({ user }: GetUserQuery) {
  return user;
}
