import { RiDoorOpenFill, RiMore2Fill, RiSettings2Line } from '@remixicon/react';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '@tomorrow/ui/theme';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { useCallback, useState } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { UserAvatar } from '@tomorrow/ui';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const UserContainer = ({ settingsLink }: { settingsLink?: string }) => {
  const auth = useAuth();
  const { data: user } = useCurrentUser();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<HTMLElement>();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = 'menu-mobile';

  const openMobileMenu: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setMobileMoreAnchorEl(undefined);
  }, []);

  const handleOnLinkClick = useCallback(() => {
    setMobileMoreAnchorEl(undefined);
  }, []);

  const handleLogout = useCallback(() => {
    handleOnLinkClick();

    if (auth.status === 'authenticated' && auth.logout) {
      auth.logout(window.location.origin);
    }
  }, [auth, handleOnLinkClick]);

  return (
    <Box alignItems="center" display="flex">
      <Box alignItems="center" display="flex">
        {user && <Box marginRight="8px">{<UserAvatar user={user} />}</Box>}
        <Hidden smDown>
          <div>
            <Typography>
              <Box component="span" fontWeight="600">
                {user?.firstName} {user?.lastName}
              </Box>
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Not you?{' '}
              <Box component="span" display="inline-flex">
                <Link component="button" onClick={handleLogout}>
                  <Box fontWeight="600">Log out</Box>
                </Link>
              </Box>
            </Typography>
          </div>

          {settingsLink && (
            <Box
              sx={{
                marginX: 2,
                alignSelf: 'stretch',
                display: 'flex',
                paddingY: 0.7,
              }}
            >
              <Divider orientation="vertical" />
            </Box>
          )}
        </Hidden>
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={mobileMenuId}
          keepMounted
          onClose={closeMobileMenu}
          open={isMobileMenuOpen}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {settingsLink ? (
            <RouterLink onClick={handleOnLinkClick} to={settingsLink}>
              <MenuItem>
                <IconButton size="large">
                  <RiSettings2Line color={theme.colors.secondaryHover} size={22} />
                </IconButton>

                <Typography color="text.primary">Settings</Typography>
              </MenuItem>
            </RouterLink>
          ) : null}

          <MenuItem onClick={handleLogout}>
            <IconButton size="large">
              <RiDoorOpenFill color={theme.colors.muiError} size={22} />
            </IconButton>
            <Typography color="error">Log Out</Typography>
          </MenuItem>
        </Menu>
        <Hidden smDown>
          {settingsLink ? (
            <RouterLink to={settingsLink}>
              <Tooltip enterTouchDelay={0} title="Settings">
                <IconButton size="large">
                  <RiSettings2Line color={theme.colors.secondaryHover} size={22} />
                </IconButton>
              </Tooltip>
            </RouterLink>
          ) : null}
        </Hidden>
        <Hidden smUp>
          <Box color="white">
            <IconButton
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              aria-label="show more"
              onClick={openMobileMenu}
              size="large"
              style={{ padding: 0 }}
            >
              <RiMore2Fill color={theme.colors.secondaryHover} />
            </IconButton>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default UserContainer;
