import * as Types from '../types.generated.js';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type AdminSplitOrderMutationVariables = Types.Exact<{
  inputData: Types.SplitOrderInput;
}>;

export type AdminSplitOrderMutation = {
  __typename?: 'Mutation';
  adminSplitOrder?: {
    __typename?: 'AdminSplitOrderMutation';
    order?: { __typename?: 'OrderType'; friendlyId?: string | null } | null;
    error?: { __typename?: 'ErrorType'; message: string } | null;
  } | null;
};

export const AdminSplitOrderDocument = `
    mutation adminSplitOrder($inputData: SplitOrderInput!) {
  adminSplitOrder(inputData: $inputData) {
    order {
      friendlyId
    }
    error {
      message
    }
  }
}
    `;

export const useAdminSplitOrderMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AdminSplitOrderMutation, TError, AdminSplitOrderMutationVariables, TContext>,
) => {
  return useMutation<AdminSplitOrderMutation, TError, AdminSplitOrderMutationVariables, TContext>({
    mutationKey: ['adminSplitOrder'],
    mutationFn: (variables?: AdminSplitOrderMutationVariables) =>
      fetcher<AdminSplitOrderMutation, AdminSplitOrderMutationVariables>(AdminSplitOrderDocument, variables)(),
    ...options,
  });
};

useAdminSplitOrderMutation.getKey = () => ['adminSplitOrder'];

useAdminSplitOrderMutation.fetcher = (variables: AdminSplitOrderMutationVariables, options?: RequestInit['headers']) =>
  fetcher<AdminSplitOrderMutation, AdminSplitOrderMutationVariables>(AdminSplitOrderDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdminSplitOrderMutation(
 *   ({ query, variables }) => {
 *     const { inputData } = variables;
 *     return HttpResponse.json({
 *       data: { adminSplitOrder }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAdminSplitOrderMutation = (
  resolver: GraphQLResponseResolver<AdminSplitOrderMutation, AdminSplitOrderMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<AdminSplitOrderMutation, AdminSplitOrderMutationVariables>('adminSplitOrder', resolver, options);
