import {
  GetOrderForSplitQuery,
  useGetOrderForSplitQuery,
} from '../api-clients/falcon-api/graphql/queries/getOrderForSplit.generated';

export function useGetOrderForSplit(orderId: string | null) {
  return useGetOrderForSplitQuery(
    { friendlyId: orderId },
    {
      enabled: !!orderId,
      staleTime: Infinity,
      meta: {
        errorMessage: `Something went wrong getting your Order Information for id ${orderId}.  Please try again later.`,
      },
      select: transformGetOrderByIdQuery,
      retry: 3,
    },
  );
}

function transformGetOrderByIdQuery({ adminOrdersFiltered }: GetOrderForSplitQuery) {
  return adminOrdersFiltered?.edges?.length ? adminOrdersFiltered.edges[0]?.node : null;
}

export type OrderForSplitType = ReturnType<typeof useGetOrderForSplit>['data'];
export type OrderForSplitLineItemType = NonNullable<OrderForSplitType>['lineItems'];
