import GoogleLogo from '../assets/google.svg?react';

export function LoginScreen({ onLogin }: { onLogin: () => void }) {
  return (
    <div className="flex h-full justify-center">
      <div className="mt-32 flex flex-col gap-3">
        <div className="text-center">
          <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">Login to your account</p>
          <p className="mt-2 text-sm leading-4 text-slate-600">You must be logged in.</p>
        </div>
        <button
          className="inline-flex h-10 w-full cursor-pointer items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-bold text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60"
          onClick={() => onLogin()}
        >
          <GoogleLogo className="size-[18px]" />
          Continue with Google
        </button>
      </div>
    </div>
  );
}
