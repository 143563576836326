import { Checkbox } from '../../../../../../marketplace/src/components/Checkbox';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { OrderForSplitLineItemType } from '../../../../hooks/useGetOrderForSplit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

type SelectListProps = {
  items: OrderForSplitLineItemType;
  checkedItems: Record<string, boolean>;
  setFunction: (items: (prev: Record<string, boolean>) => Record<string, boolean>) => void;
  handleChange: (
    item: string,
    setFunction: (items: (prev: Record<string, boolean>) => Record<string, boolean>) => void,
  ) => void;
  orderId: string;
};

export function SelectList({ items, checkedItems, handleChange, setFunction, orderId }: SelectListProps) {
  return (
    <Box sx={{ flex: '1 0 45%' }}>
      <Box borderRadius="2px 2px 0 0" py={1} sx={{ backgroundColor: ColorsV2.gray_light }}>
        <Typography align="center" variant="h6">
          {orderId}
        </Typography>
      </Box>
      <Box
        borderRadius="0 0 2px 2px"
        height="300px"
        overflow="scroll"
        padding={2}
        sx={{ backgroundColor: ColorsV2.white }}
      >
        {items.map((item: OrderForSplitLineItemType[number], itemIndex: number) => (
          <div key={item.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems[item.id] || false}
                  onChange={() => handleChange(item.id, setFunction)}
                />
              }
              label={<HcpcsLabel display={item.displayText} hcpcs={item.hcpcsCode!.code} />}
              sx={{ margin: 0 }}
            />
            {itemIndex !== items.length - 1 ? <Divider /> : <></>}
          </div>
        ))}
      </Box>
    </Box>
  );
}

function HcpcsLabel({ hcpcs, display }: { hcpcs: string; display: string }) {
  return (
    <Box sx={{ padding: '8px 0' }}>
      <Typography style={{ fontWeight: 600 }}>{hcpcs}</Typography>
      <Typography color="textSecondary">{display}</Typography>
    </Box>
  );
}
