import { OrderHeader } from './components/OrderHeader';
import { OrdersForm } from './components/OrdersForm';
import { useGetOrderForSplit } from '../../../hooks/useGetOrderForSplit';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

export function OrderSplitting() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { data: order, isLoading } = useGetOrderForSplit(orderId);

  if (!orderId) {
    return <Box margin="auto">Order id was not provided. Please return to OMS and select an order to split</Box>;
  }

  if (isLoading) {
    return (
      <Box margin="auto">
        <CircularProgress />
      </Box>
    );
  }

  return order ? (
    <Box padding={4}>
      <OrderHeader order={order} />
      <Divider />
      <OrdersForm order={order} />
    </Box>
  ) : (
    <Box margin="auto">Something went wrong. Order with id {orderId} not found</Box>
  );
}
