import * as Types from '../types.generated.js';

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  InfiniteData,
} from '@tanstack/react-query';
import { fetcher } from '../client';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type GetOrderForSplitQueryVariables = Types.Exact<{
  friendlyId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetOrderForSplitQuery = {
  __typename?: 'Query';
  adminOrdersFiltered?: {
    __typename?: 'OrderTypeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename?: 'OrderTypeEdge';
      node?: {
        __typename?: 'OrderType';
        id: string;
        friendlyId?: string | null;
        createdAt: string;
        urgent: boolean;
        referralDate?: string | null;
        referralSource?: Types.AppOrderReferralSourceChoices | null;
        referralChannel?: Types.AppOrderReferralChannelChoices | null;
        lineItems: Array<{
          __typename?: 'OrderLineItemType';
          id: string;
          quantity: number;
          reimbursed: boolean;
          displayText: string;
          productItem?: { __typename?: 'OrderProductItemType'; id: string } | null;
          hcpcsCode?: { __typename?: 'HcpcsType'; code: string; friendlyDescription?: string | null } | null;
          subproduct?: { __typename?: 'SubproductType'; description: string } | null;
        }>;
        falconStatus?: {
          __typename?: 'OrderStatusHistoryType';
          status: Types.AppOrderStatusHistoryStatusChoices;
        } | null;
        patient: { __typename?: 'PatientType'; id: string; firstName: string; lastName: string };
        referrer?: { __typename?: 'PersonType'; id: string; firstName: string; lastName: string } | null;
        referringProvider?: { __typename?: 'ProviderType'; id: string; firstName: string; lastName: string } | null;
        referringOrg?: { __typename?: 'OrgSlimType'; name?: string | null; orgTypeEnum: Types.OrgTypes } | null;
      } | null;
    } | null>;
  } | null;
};

export type OrderForSplitFragment = {
  __typename?: 'OrderType';
  id: string;
  friendlyId?: string | null;
  createdAt: string;
  urgent: boolean;
  referralDate?: string | null;
  referralSource?: Types.AppOrderReferralSourceChoices | null;
  referralChannel?: Types.AppOrderReferralChannelChoices | null;
  lineItems: Array<{
    __typename?: 'OrderLineItemType';
    id: string;
    quantity: number;
    reimbursed: boolean;
    displayText: string;
    productItem?: { __typename?: 'OrderProductItemType'; id: string } | null;
    hcpcsCode?: { __typename?: 'HcpcsType'; code: string; friendlyDescription?: string | null } | null;
    subproduct?: { __typename?: 'SubproductType'; description: string } | null;
  }>;
  falconStatus?: { __typename?: 'OrderStatusHistoryType'; status: Types.AppOrderStatusHistoryStatusChoices } | null;
  patient: { __typename?: 'PatientType'; id: string; firstName: string; lastName: string };
  referrer?: { __typename?: 'PersonType'; id: string; firstName: string; lastName: string } | null;
  referringProvider?: { __typename?: 'ProviderType'; id: string; firstName: string; lastName: string } | null;
  referringOrg?: { __typename?: 'OrgSlimType'; name?: string | null; orgTypeEnum: Types.OrgTypes } | null;
};

export const OrderForSplitFragmentDoc = `
    fragment OrderForSplitFragment on OrderType {
  id
  friendlyId
  createdAt
  urgent
  lineItems {
    id
    quantity
    productItem {
      id
    }
    reimbursed
    displayText
    hcpcsCode {
      code
      friendlyDescription
    }
    subproduct {
      description
    }
  }
  falconStatus {
    status
  }
  patient {
    id
    firstName
    lastName
  }
  referralDate
  referralSource
  referralChannel
  referrer {
    id
    firstName
    lastName
  }
  referringProvider {
    id
    firstName
    lastName
  }
  referringOrg {
    name
    orgTypeEnum
  }
}
    `;
export const GetOrderForSplitDocument = `
    query getOrderForSplit($friendlyId: String) {
  adminOrdersFiltered(friendlyId: $friendlyId) {
    totalCount
    edges {
      node {
        ...OrderForSplitFragment
      }
    }
  }
}
    ${OrderForSplitFragmentDoc}`;

export const useGetOrderForSplitQuery = <TData = GetOrderForSplitQuery, TError = unknown>(
  variables?: GetOrderForSplitQueryVariables,
  options?: Omit<UseQueryOptions<GetOrderForSplitQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<GetOrderForSplitQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetOrderForSplitQuery, TError, TData>({
    queryKey: variables === undefined ? ['getOrderForSplit'] : ['getOrderForSplit', variables],
    queryFn: fetcher<GetOrderForSplitQuery, GetOrderForSplitQueryVariables>(GetOrderForSplitDocument, variables),
    ...options,
  });
};

useGetOrderForSplitQuery.getKey = (variables?: GetOrderForSplitQueryVariables) =>
  variables === undefined ? ['getOrderForSplit'] : ['getOrderForSplit', variables];

export const useInfiniteGetOrderForSplitQuery = <TData = InfiniteData<GetOrderForSplitQuery>, TError = unknown>(
  variables: GetOrderForSplitQueryVariables,
  options: Omit<UseInfiniteQueryOptions<GetOrderForSplitQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseInfiniteQueryOptions<GetOrderForSplitQuery, TError, TData>['queryKey'];
  },
) => {
  return useInfiniteQuery<GetOrderForSplitQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['getOrderForSplit.infinite']
            : ['getOrderForSplit.infinite', variables],
        queryFn: (metaData) =>
          fetcher<GetOrderForSplitQuery, GetOrderForSplitQueryVariables>(GetOrderForSplitDocument, {
            ...variables,
            ...(metaData.pageParam ?? {}),
          })(),
        ...restOptions,
      };
    })(),
  );
};

useInfiniteGetOrderForSplitQuery.getKey = (variables?: GetOrderForSplitQueryVariables) =>
  variables === undefined ? ['getOrderForSplit.infinite'] : ['getOrderForSplit.infinite', variables];

useGetOrderForSplitQuery.fetcher = (variables?: GetOrderForSplitQueryVariables, options?: RequestInit['headers']) =>
  fetcher<GetOrderForSplitQuery, GetOrderForSplitQueryVariables>(GetOrderForSplitDocument, variables, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetOrderForSplitQuery(
 *   ({ query, variables }) => {
 *     const { friendlyId } = variables;
 *     return HttpResponse.json({
 *       data: { adminOrdersFiltered }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetOrderForSplitQuery = (
  resolver: GraphQLResponseResolver<GetOrderForSplitQuery, GetOrderForSplitQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetOrderForSplitQuery, GetOrderForSplitQueryVariables>('getOrderForSplit', resolver, options);
