const isProd = import.meta.env.MODE === 'production';

const requiredEnv: Record<string, boolean> = {
  REACT_APP_ECOMMERCE_GRAPHQL_API_URL: true,
  REACT_APP_DATADOG_RUM_APPLICATION_ID: isProd,
  REACT_APP_DATADOG_RUM_CLIENT_TOKEN: isProd,
};

export function validateEnv() {
  const missing = Object.entries(requiredEnv).filter(([key, value]) => value && !import.meta.env[key]);

  if (missing.length) {
    throw new Error(`Missing environment variables: ${missing.join(', ')}`);
  }
}
