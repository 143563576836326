import { AuthProvider } from '../contexts/AuthContext/AuthContext';
import { GeneralHeader } from '../components/Header/GeneralHeader';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Home } from '../screens/Home';
import { Layout } from '../layouts/Layout';
import { lazily } from 'react-lazily';
import { Navigate, Route } from 'react-router-dom';
import { OrderSplitting } from '../screens/applications/order-splitting/OrderSplitting';
import { PATHS } from './paths';
import { retry } from './retry';
import { Suspense } from 'react';
import UserContainer from '../components/Header/UserContainer';

const { default: App } = lazily(() => retry(() => import('../App')));
const { default: NotFound } = lazily(() => retry(() => import('../screens/NotFoundScreen')));
const { UnauthorizedScreen } = lazily(() => retry(() => import('../screens/UnauthorizedScreen')));

export const appRoutes = (
  <Route element={<AppAuth />} path="/">
    <Route element={<Layout header={<GeneralHeader homeLink={PATHS.HOME} rightContent={<UserContainer />} />} />}>
      <Route element={<Home />} index />
      <Route element={<OrderSplitting />} path="order-splitting" />
    </Route>

    <Route element={<Layout header={<GeneralHeader homeLink={PATHS.HOME} />} />}>
      <Route element={<UnauthorizedScreen />} path="unauthorized" />
      <Route element={<NotFound />} path="404" />
    </Route>
    <Route element={<Navigate replace to={PATHS.NOT_FOUND} />} path="*" />
  </Route>
);

function AppAuth() {
  // By first loading routing+auth and lazy loading the rest of the SPA like
  // this, the app can more quickly check for routes which require auth and
  // redirect to auth0 when necessary, because less JS needs to be immediately
  // loaded.
  return (
    <GoogleOAuthProvider clientId={import.meta.env['REACT_APP_GOOGLE_CLIENT_ID']}>
      <AuthProvider>
        <Suspense>
          <App />
        </Suspense>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}
